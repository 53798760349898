<template>
    <AppPanel :hasBackButton="true" :location="[title]" backPath="/companies" subtitle="Preencha os campos abaixo">
        <template #content>
            <AppFormCadastro :form="form" :service="service" :showSaveAndReturn="true" backPath="/companies"
                @onAfterSave="onAfterSave" @onBeforeSave="onBeforeSave" @onLoadDataEdit="onLoadDataEdit"
                @onLoadDataInsert="onLoadDataInsert" @onValidate="onValidate">
                <template #content>
                    <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                        <AppInfoManual nomeTelaDoManual="empresas-form" />
                        <h3 class="ml-1">{{ title }}</h3>
                    </div>
                    <TabView>
                        <TabPanel header="Geral">
                            <div class="grid">
                                <div class="field col-6 mb-0">
                                    <label for="name">Nome</label>
                                    <InputText id="name" v-model.trim="form.name"
                                        :class="{ 'p-invalid': submitted && !form.name }" autocomplete="off" autofocus
                                        required="true" />
                                    <small v-if="submitted && !form.name" class="p-error">Nome é obrigatório.</small>
                                </div>

                                <div class="field col-6 mb-0">
                                    <label for="trade_name">Razão social</label>
                                    <InputText id="trade_name" v-model.trim="form.tradeName"
                                        :class="{ 'p-invalid': submitted && !form.tradeName }" autocomplete="off"
                                        autofocus required="true" />
                                    <small v-if="submitted && !form.tradeName" class="p-error">Razão social é
                                        obrigatório.</small>
                                </div>
                            </div>
                            <div class="grid">
                                <div class="col-12 md:col-6">
                                    <div class="field-checkbox mb-2">
                                        <InputSwitch v-model="form.eSocialAtivo" inputId="eSocialAtivo" />
                                        <label class="mr-2 mb-1" for="eSocialAtivo"> Envia eventos para o
                                            eSocial</label>
                                    </div>
                                    <div class="field-checkbox mb-2">
                                        <InputSwitch v-model="form.enviarComprovanteConfirmacao"
                                            inputId="enviarComprovante" @click="form.emailComprovacao = null" />
                                        <label class="mr-2 mb-1" for="enviarComprovante">Enviar comprovante de
                                            confirmação de comparecimento</label>
                                    </div>
                                    <div v-if="form.enviarComprovanteConfirmacao" class="col-6">
                                        <div class="field">
                                            <label for="enviarComprovanteConfirmacao">Email para envio do
                                                comprovante</label>
                                            <InputText id="enviarComprovanteConfirmacao"
                                                v-model.trim="form.emailComprovacao"
                                                :class="{ 'p-invalid': form.enviarComprovanteConfirmacao && submitted && !form.emailComprovacao }"
                                                autocomplete="off" autofocus required="true" />
                                            <small
                                                v-if="form.enviarComprovanteConfirmacao && submitted && !form.emailComprovacao"
                                                class="p-error">O email para o envio da confirmação é
                                                obrigatório</small>
                                        </div>
                                    </div>
                                    <div class="field-checkbox mb-2">
                                        <InputSwitch v-model="form.permiteAgendamentosAutomaticos" />
                                        <label class="mr-2 mb-1" for="binary"> Permite agendamentos automáticos</label>
                                    </div>
                                    <div class="field-checkbox mb-2">
                                        <InputSwitch v-model="form.permiteGerarAsoReservaHorario" />
                                        <label class="mr-2 mb-1" for="binary"> Permite gerar ASO para reservas de
                                            horários</label>
                                    </div>
                                    <div class="field-checkbox mb-2">
                                        <InputSwitch v-model="form.gerarCreditoAutomatico" />
                                        <label class="mr-2 mb-1" for="binary"> Gerar créditos de depósito antecipado
                                            automaticamente</label>
                                    </div>
                                    <div class="field-checkbox mb-2">
                                        <InputSwitch v-model="form.permiteConfirmacaoPrestador"
                                            inputId="permiteConfirmacaoPrestador"
                                            @click="form.tempoLimitePrestador = null" />
                                        <label class="mr-2 mb-1" for="permiteConfirmacaoPrestador">Enviar link ao
                                            prestador para confirmação de horários</label>
                                    </div>
                                    <div v-if="form.permiteConfirmacaoPrestador" class="col-6">
                                        <div class="field">
                                            <label for="tempoLimitePrestador">Tempo limite de retorno do prestador para
                                                agendamento</label>
                                            <InputNumber id="tempoLimitePrestador" v-model="tempoLimitePrestador"
                                                placeholder="Horas"
                                                :class="{ 'p-invalid': form.enviarComprovanteConfirmacao && submitted && !form.tempoLimitePrestador }"
                                                suffix=" Hora(s)" />
                                            <small
                                                v-if="form.enviarComprovanteConfirmacao && submitted && !form.tempoLimitePrestador"
                                                class="p-error">O Tempo limite de retorno do prestador para agendamento
                                                é obrigatório</small>
                                        </div>
                                    </div>
                                    <div class="field-checkbox mb-2">
                                        <InputSwitch v-model="form.integracaoSoc" @click="limparDados" />
                                        <label class="mr-2 mb-1" for="binary"> Integração com SOC</label>
                                    </div>
                                    <div class="grid">
                                        <div class="field col-6">
                                            <label for="agendaUtilizada">Agenda utilizada</label>
                                            <Dropdown id="agendaUtilizada" v-model="form.agendaUtilizada"
                                                :options="enumAgendaUtilizada" :showClear="true" optionLabel="label"
                                                optionValue="value" placeholder="Selecione..." />
                                        </div>
                                    </div>
                                    <div class="grid">
                                        <div class="field col-6">
                                            <label for="agendaUtilizada">Método de liberação de hierarquia</label>
                                            <Dropdown
                                                id="agendaUtilizada"
                                                v-model="form.metodoLiberacaoHierarquia"
                                                :options="enumMetodoLiberacao"
                                                optionLabel="label"
                                                optionValue="value"
                                                placeholder="Selecione..."
                                                @change="updateModelMetodoLiberacaoHierarquia"
                                            />
                                        </div>
                                    </div>
                                    <div class="grid">
                                        <div class="field col-6">
                                            <label for="emailRetorno">Email para receber respostas</label>
                                            <InputText id="emailRetorno" v-model.trim="form.emailRetorno"
                                                :class="{ 'p-invalid': !form.emailRetorno && submitted }"
                                                autocomplete="off" autofocus required="true" />
                                        </div>
                                    </div>
                                    <div class="grid">
                                        <div class="field col-6">
                                            <label for="emailSemHierarquia">Email para envio quando funcionario não tem
                                                hierarquia</label>
                                            <InputText id="emailSemHierarquia" v-model.trim="form.emailSemHierarquia"
                                                autocomplete="off" autofocus required="false" />
                                        </div>
                                    </div>
                                </div>
                                <div v-if="form.id" class="col-12 md:col-6">
                                    <div class="flex">
                                        <div class="field mr-3 mt-1">
                                            <label v-if="!form.urlLogo" for="Logo">Logo</label>
                                            <div v-if="form.urlLogo" class="user-photo-template">
                                                <Image :src="form.urlLogo" class="user-photo" height="70" preview />
                                            </div>
                                        </div>
                                        <div class="field mr-3 mt-1">
                                            <span v-if="form.urlLogo" class="p-button p-component p-button-link"
                                                @click="removerLogo">
                                                <span class="p-button-icon p-button-icon-left pi pi-trash"></span>
                                                Remover
                                            </span>

                                            <FileUpload id="foto" :maxFileSize="1000000" accept="image/*" auto
                                                chooseLabel="Upload" class="p-button-link" customUpload mode="basic"
                                                @uploader="uploadLogo" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="form.integracaoSoc" class="">
                                <div class="field">
                                    <label for="integracoes">Integrações</label>
                                    <MultiSelect id="integracoes" v-model="integracoesSelecionadas"
                                        :class="{ 'p-invalid': form.integracaoSoc && submitted && !integracoesSelecionadas.length }"
                                        :filter="true" :options="integracoes" display="chip" optionLabel="name" />
                                    <small v-if="form.integracaoSoc && submitted && !integracoesSelecionadas.length"
                                        class="p-error">Campo obrigatório.</small>
                                </div>

                                <div class="field">
                                    <label for="codigoEmpresaPrincipal">Código da Empresa Principal</label>
                                    <InputText id="codigoEmpresaPrincipal" v-model.trim="form.codigoEmpresaPrincipal"
                                        :class="{ 'p-invalid': form.integracaoSoc && submitted && !form.codigoEmpresaPrincipal }"
                                        autocomplete="off" autofocus required="true" />
                                    <small v-if="form.integracaoSoc && submitted && !form.codigoEmpresaPrincipal"
                                        class="p-error">Código da Empresa Principal é obrigatório.</small>
                                </div>

                                <div class="field">
                                    <label for="chaveAcesso">Chave de Acesso/Password</label>
                                    <InputText id="chaveAcesso" v-model.trim="form.chaveAcesso"
                                        :class="{ 'p-invalid': form.integracaoSoc && submitted && !form.chaveAcesso }"
                                        autocomplete="off" autofocus required="true" />
                                    <small v-if="form.integracaoSoc && submitted && !form.chaveAcesso"
                                        class="p-error">Chave de Acesso/Password é obrigatório.</small>
                                </div>

                                <div class="field">
                                    <label for="username">Username</label>
                                    <InputText id="username" v-model.trim="form.username"
                                        :class="{ 'p-invalid': form.integracaoSoc && submitted && !form.username }"
                                        autocomplete="off" autofocus required="true" />
                                    <small v-if="form.integracaoSoc && submitted && !form.username"
                                        class="p-error">Username é obrigatório.</small>
                                </div>

                                <div class="field">
                                    <label for="codigoResponsavel">Código Responsável</label>
                                    <InputText id="codigoResponsavel" v-model.trim="form.codigoResponsavel"
                                        :class="{ 'p-invalid': form.integracaoSoc && submitted && !form.codigoResponsavel }"
                                        autocomplete="off" autofocus required="true" />
                                    <small v-if="form.integracaoSoc && submitted && !form.codigoResponsavel"
                                        class="p-error">Código Responsável é obrigatório.</small>
                                </div>
                                <div class="field">
                                    <label for="codigoUsuario">Código Usuário SOC</label>
                                    <InputText id="codigoUsuario" v-model.trim="form.codigoUsuario"
                                        :class="{ 'p-invalid': form.integracaoSoc && submitted && !form.codigoUsuario }"
                                        autocomplete="off" autofocus required="true" />
                                    <small v-if="form.integracaoSoc && submitted && !form.codigoUsuario"
                                        class="p-error">Código Usuário SOC é obrigatório.</small>
                                </div>
                                <div class="field">
                                    <label for="qtdProcessarImportacaoSoc">Quantidade de importações simultâneas</label>
                                    <InputText id="qtdProcessarImportacaoSoc"
                                        v-model.trim="form.qtdProcessarImportacaoSoc"
                                        :class="{ 'p-invalid': form.integracaoSoc && submitted && !form.qtdProcessarImportacaoSoc }"
                                        autocomplete="off" autofocus type="number" required="true" />
                                    <small v-if="form.integracaoSoc && submitted && !form.qtdProcessarImportacaoSoc"
                                        class="p-error">Quantidade de importações simultâneas é obrigatório.</small>
                                </div>
                                <div class="field">
                                    <label for="codigoAsoGedSOC">Tipos de GED para buscar os ASOS do funcionário
                                        (Separado por vírgula)</label>
                                    <InputText id="codigoAsoGedSOC" v-model.trim="form.codigoAsoGedSOC"
                                        :class="{ 'p-invalid': form.integracaoSoc && submitted && !form.codigoAsoGedSOC }"
                                        autocomplete="off" autofocus required="true" />
                                    <small v-if="form.integracaoSoc && submitted && !form.codigoAsoGedSOC"
                                        class="p-error">Tipos de GED para buscar os ASOS do funcionário é
                                        obrigatório.</small>
                                </div>
                                <Divider v-if="form?.integracaoSoc" align="left" class="m-4">
                                    <span>Configuração de emissão do ASO</span>
                                </Divider>
                                <div class="grid">
                                    <div class="field col-12">
                                        <label v-if="form?.integracaoSoc" for="geradorAso">Gerador do Aso</label>
                                        <Dropdown v-if="form?.integracaoSoc" id="geradorAso"
                                            v-model="configAso.geradorAso"
                                            :class="{ 'p-invalid': submitted && form.integracaoSoc && !configAso?.geradorAso }"
                                            :options="enumGeradorAso" :showClear="true" optionLabel="label"
                                            optionValue="value" placeholder="Selecione..." />
                                        <small v-if="submitted && form.integracaoSoc && !configAso?.geradorAso"
                                            class="p-error">
                                            Campo obrigatório.</small>
                                    </div>
                                </div>
                                <div class="grid">
                                    <div class="field col-4">
                                        <label v-if="form?.integracaoSoc" for="primeiraVia">1° Via</label>
                                        <Dropdown v-if="form?.integracaoSoc" id="primeiraVia"
                                            v-model="configAso.primeiraVia" :options="enumDestinatarioViaAso"
                                            :showClear="true" optionLabel="label" optionValue="value"
                                            placeholder="Selecione..." />
                                    </div>
                                    <div class="field col-4">
                                        <label v-if="form?.integracaoSoc" for="segundaVia">2° Via</label>
                                        <Dropdown v-if="form?.integracaoSoc" id="segundaVia"
                                            v-model="configAso.segundaVia" :options="enumDestinatarioViaAso"
                                            :showClear="true" optionLabel="label" optionValue="value"
                                            placeholder="Selecione...">
                                        </Dropdown>
                                    </div>
                                    <div class="field col-4">
                                        <label v-if="form?.integracaoSoc" for="terceiraVia">3° Via</label>
                                        <Dropdown v-if="form?.integracaoSoc" id="terceiraVia"
                                            v-model="configAso.terceiraVia" :options="enumDestinatarioViaAso"
                                            :showClear="true" optionLabel="label" optionValue="value"
                                            placeholder="Selecione...">
                                        </Dropdown>
                                    </div>
                                </div>
                                <div class="grid">
                                    <div class="field col-4">
                                        <label for="prestadorExameParticular"> Prestador responsável pelos exames
                                            particulares </label>
                                        <DropdownPrestador id="prestadorExameParticular"
                                            v-model="form.prestadorExameParticular" :autoLoad="true"
                                            :idValue="form.prestadorExameParticularId" />
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel :disabled="!form.eSocialAtivo" header="eSocial">
                            <div class="grid">
                                <div class="field col-2">
                                    <label for="eSocialVersao">Versão do eSocial</label>
                                    <Dropdown id="eSocialVersao" v-model="form.eSocialVersao"
                                        :class="{ 'p-invalid': submitted && !form.eSocialVersao }"
                                        :options="eSocialVersoes" optionLabel="name" optionValue="id" />
                                    <small v-if="submitted && !form.eSocialVersao" class="p-error"> Versão do eSocial
                                        deve ser informada. </small>
                                </div>
                                <div class="field col-2">
                                    <label for="eSocialCertificadoDiasAviso">Aviso de certificado vencendo</label>
                                    <div class="p-inputgroup">
                                        <InputNumber id="eSocialCertificadoDiasAviso"
                                            v-model="form.eSocialCertificadoDiasAviso"
                                            :class="{ 'p-invalid': submitted && !form.eSocialCertificadoDiasAviso }"
                                            autofocus required="true" />
                                        <span class="p-inputgroup-addon w-30rem">Dias de antecedência</span>
                                    </div>
                                    <small v-if="submitted && !form.eSocialCertificadoDiasAviso" class="p-error">
                                        Aviso de certificado vencendo deve ser informado.
                                    </small>
                                </div>
                            </div>
                            <h6 class="mb-4">Certificado digital</h6>
                            <FormCertificado v-if="form.id" :certificado="form.eSocialCertificado"
                                :companyId="form.id" />
                            <div v-else>
                                <div class="field col-12 mb-0">
                                    <p>Configure o certificado após <strong>salvar a empresa</strong>.</p>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Dados Bancários" v-if="hasPermissaoEditarDadosBancarios" style="color: red">
                            <div>
                                <h5 class="mt-4 mb-4">Dados para faturamento</h5>
                                <div class="grid">
                                    <div class="field col">
                                        <label for="emailCopiaFinanceiro">Email cópia do financeiro</label>
                                        <InputText id="emailCopiaFinanceiro" v-model.trim="form.emailCopiaFinanceiro"
                                            :class="{ 'p-invalid': submitted && !form.emailCopiaFinanceiro }"
                                            autocomplete="off" autofocus required="true" />
                                        <small v-if="submitted && !form.emailCopiaFinanceiro" class="p-error"
                                            id="emailCopiaFinanceiroIsNull">
                                            Preenchimento obrigatório.
                                        </small>
                                    </div>
                                </div>
                                <div class="grid">
                                    <div class="field col">
                                        <label for="metodoPagamento">Método de pagamento</label>
                                        <Dropdown id="metodoPagamento" v-model="form.metodoPagamento"
                                            :options="metodoPagamento" optionLabel="name" optionValue="value"
                                            dataKey="id" placeholder="Selecione..." />
                                    </div>
                                    <div v-if="form.metodoPagamento === metodoPagamentoEnum.PIX" class="field col">
                                        <label for="chavePix">Chave Pix</label>
                                        <InputText id="chavePix" v-model="form.chavePix" :options="metodoPagamento"
                                            :class="{ 'p-invalid': submitted && form.metodoPagamento === metodoPagamentoEnum.PIX && !form.chavePix }" />
                                        <small class="p-error"
                                            v-if="submitted && form.metodoPagamento === metodoPagamentoEnum.PIX && !form.chavePix">
                                            Preenchimento obrigatório
                                        </small>
                                    </div>
                                </div>
                                <div v-if="form.metodoPagamento != metodoPagamentoEnum.PIX">
                                    <div class="field">
                                        <label for="nomeTitular">Nome titular da conta</label>
                                        <InputText id="titularConta" v-model.trim="form.nomeTitular" autocomplete="off"
                                            :class="{ 'p-invalid': submitted && !form.nomeTitular }" />
                                        <small class="p-error" v-if="submitted && !form.nomeTitular"> Preenchimento
                                            obrigatório </small>
                                    </div>
                                    <div class="grid">
                                        <div class="field col-6">
                                            <label for="cpfTitularConta">CPF titular</label>
                                            <InputMask id="cpfTitularConta" mask="999.999.999-99"
                                                v-model.trim="form.cpfTitularConta" autocomplete="off" />
                                        </div>
                                        <div class="field col-6">
                                            <label for="cnpjTitularConta">CNPJ titular</label>
                                            <InputMask id="cnpjTitularConta" mask="99.999.999/9999-99"
                                                v-model.trim="form.cnpjTitularConta" autocomplete="off"
                                                :class="{ 'p-invalid': submitted && !form.cnpjTitularConta && !form.cpfTitularConta }" />
                                            <small class="p-error"
                                                v-if="submitted && !form.cnpjTitularConta && !form.cpfTitularConta">
                                                Preenchimento obrigatório quando não possuir CPF do titular
                                            </small>
                                        </div>
                                    </div>
                                    <div class="grid">
                                        <div class="field col-6">
                                            <label for="codigoBanco">Código do Banco</label>
                                            <InputMask id="codigoBanco" mask="999" v-model.trim="form.codigoBanco"
                                                autocomplete="off"
                                                :class="{ 'p-invalid': submitted && !form.codigoBanco }" />
                                            <small class="p-error" v-if="submitted && !form.codigoBanco"> Preenchimento
                                                obrigatório </small>
                                        </div>
                                        <div class="field col-6">
                                            <label for="nomeBanco">Nome do Banco</label>
                                            <InputText id="nomeBanco" v-model.trim="form.nomeBanco" autocomplete="off"
                                                :class="{ 'p-invalid': submitted && !form.nomeBanco }" />
                                            <small class="p-error" v-if="submitted && !form.nomeBanco"> Preenchimento
                                                obrigatório </small>
                                        </div>
                                    </div>
                                    <div class="grid">
                                        <div class="field col-6">
                                            <label for="codigoAgencia">Codigo Agência</label>
                                            <InputText id="codigoAgencia" v-model.trim="form.codigoAgencia"
                                                autocomplete="off"
                                                :class="{ 'p-invalid': submitted && !form.codigoAgencia }" />
                                            <small class="p-error" v-if="submitted && !form.codigoAgencia">Preenchimento
                                                obrigatório </small>
                                            <small class="p-info">Formatos aceitos: 0000 | 0000-0</small>
                                        </div>
                                        <div class="field col-6">
                                            <label for="contaCorrente">Conta Corrente</label>
                                            <InputText id="contaCorrente" v-model.trim="form.contaCorrente"
                                                autocomplete="off"
                                                :class="{ 'p-invalid': submitted && !form.contaCorrente }" />
                                            <small class="p-error" v-if="submitted && !form.contaCorrente">Preenchimento
                                                obrigatório </small>
                                            <small class="p-info">Formatos aceitos: 00000-0 | 000000-0 | 0000000-0
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                    </TabView>
                </template>
            </AppFormCadastro>
        </template>
    </AppPanel>
</template>

<script>
import BaseService from '../../services/BaseService';
import EventBus from '../../event-bus';
import FormCertificado from '../esocial/certificados/Form';
import { getClientBase } from '@/services/http';
import GeradorAsoEnum from '../../enums/GeradorAsoEnum';
import DestinatarioViaAsoEnum from '../../enums/DestinatarioViaAsoEnum';
import EnumAgendaUtilizadaEmpresa from '../../enums/EnumAgendaUtilizadaEmpresa';
import DropdownPrestador from '../prestadores/components/DropdownPrestador.vue';
import MetodoPagamentoEnum from '../../enums/MetodoPagamentoEnum';
import { showWarning } from '../../utils/Toast';
import AppInfoManual from '../../components/AppInfoManual.vue';
import EnumMetodoLiberacaoHierarquia from '../../enums/EnumMetodoLiberacaoHierarquia';

export default {
    components: { DropdownPrestador, FormCertificado, AppInfoManual },
    data() {
        return {
            service: null,
            form: {
                agendaUtilizada: 'SOC',
                metodoLiberacaoHierarquia: 'RISCO_APLICADO_FUNCIONARIO'
            },
            submitted: false,
            integracoes: [],
            integracoesSelecionadas: [],
            showFormCertificado: false,
            configAso: {},
            enumGeradorAso: [],
            enumDestinatarioViaAso: [],
            enumAgendaUtilizada: [],
            eSocialVersoes: [{ id: '1.1', name: '1.1' }],
            metodoPagamentoEnum: MetodoPagamentoEnum,
            codigoAsoGedSOC: false,
            enumMetodoLiberacao: []
        };
    },
    created() {
        this.service = new BaseService('/companies');
        this.$serviceIntegracoes = new BaseService('/company/integracao');
        this.$serviceBase = getClientBase();
    },
    watch: {
        'form.prestadorExameParticular'() {
            this.form.prestadorExameParticularId = this.form?.prestadorExameParticular?.id;
        },
        async 'form.codigoAsoGedSOC'() {
            const verificarFormato = this.verificaNumerosSeparadosPorVirgula(this.form.codigoAsoGedSOC);
            this.codigoAsoGedSOC = false;
            if (verificarFormato) {
                this.codigoAsoGedSOC = true;
            }
        }
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Editar empresa' : 'Adicionar empresa';
        },
        metodoPagamento() {
            return [
                { name: 'PIX', value: this.metodoPagamentoEnum.PIX },
                { name: 'Faturamento', value: this.metodoPagamentoEnum.FATURAMENTO },
                { name: 'Transferência', value: this.metodoPagamentoEnum.TRANSFERENCIA }
            ];
        },
        hasPermissaoEditarDadosBancarios() {
            return this.$checkPermission('configuracoes_empresa:dados_bancarios');
        },
        tempoLimitePrestador: {
            get() {
                return this.form.tempoLimitePrestador;
            },
            set(value) {
                if (value < 1) {
                    this.form.tempoLimitePrestador = 1;
                } else if (value > 99) {
                    this.form.tempoLimitePrestador = 99;
                } else {
                    this.form.tempoLimitePrestador = value;
                }
            }
        }
    },
    async mounted() {
        this.geradorAsoOptions();
        this.destinatarioViaAsoOptions();
        this.agendaUtilizadaOptions();
        this.metodosLiberacaoHierarquiaOptions();
        await this.loadConfigAso();
    },
    methods: {
        updateModelMetodoLiberacaoHierarquia(selectedItem) {
            const selectedValue = this.enumMetodoLiberacao.find(item => item.value === selectedItem.value)
            this.form.metodoLiberacaoHierarquia = selectedValue.value;
        },
        hasError() {
            if (!this.form.name) return true;

            if (!this.form.tradeName) return true;

            if (!this.form.eSocialVersao) return true;

            if (!this.form.eSocialCertificadoDiasAviso) return true;

            if (this.form.integracaoSoc) {
                if (!this.integracoesSelecionadas.length) return true;

                if (!this.form.chaveAcesso) return true;

                if (!this.form.codigoResponsavel) return true;

                if (!this.form.codigoUsuario) return true;

                if (!this.configAso?.geradorAso) return true;

                if (!this.codigoAsoGedSOC) return true;

                if (!this.form.username) return true;
            }

            if (this.hasPermissaoEditarDadosBancarios) {
                if (!this.form.emailCopiaFinanceiro) return true;
                if (this.form.metodoPagamento === MetodoPagamentoEnum.PIX) {
                    if (!this.form.chavePix) return true;
                }
                if (this.form.metodoPagamento != MetodoPagamentoEnum.PIX) {
                    if (
                        !this.form.nomeTitular ||
                        !this.form.cpfTitularConta ||
                        !this.form.cnpjTitularConta ||
                        !this.form.codigoBanco ||
                        !this.form.nomeBanco ||
                        !this.form.codigoAgencia ||
                        !this.form.contaCorrente
                    ) {
                        return true;
                    }
                }
            }
            if (this.form.permiteConfirmacaoPrestador && !this.form.tempoLimitePrestador) {
                return true;
            }
        },
        limparDados() {
            this.integracoesSelecionadas = [];
            this.form.codigoEmpresaPrincipal = null;
            this.form.chaveAcesso = null;
            this.form.codigoResponsavel = null;
            this.form.codigoUsuario = null;
            this.form.username = null;
            this.submitted = false;
        },
        async carregarDados() {
            if (this.$route.params.id) {
                this.carregarDadosEdicao();
            }
        },
        async onLoadDataEdit(data) {
            this.form = data;
            this.integracoes = data.integracoes || [];
            this.aplicarIntegracoesSelecionadas();
        },
        aplicarIntegracoesSelecionadas() {
            this.integracoesSelecionadas = this.integracoes.filter((p) => p.checked);
        },
        async onValidate(cbSuccess, cbError) {
            if (this.hasError()) {
                showWarning(this.$toast, 'Existem campos não informados. Favor verificar as abas Geral, eSocial e/ou Dados bancários');
                return cbError();
            }

            return cbSuccess();
        },
        async onBeforeSave() {
            this.submitted = true;
            this.form.integracoes = this.integracoesSelecionadas.map((p) => p.type);
        },
        async onAfterSave(response) {
            this.submitted = false;
            this.form = { ...response.data };
            await this.salvarConfigAso();
            this.$router.push('/companies/' + this.form.id);
            EventBus.emit('empresa-salva');

            setTimeout(() => {
                this.form.prestadorExameParticularId = response.data.prestadorExameParticularId;
            }, 100);
        },
        async onLoadDataInsert() {
            const { data } = await this.$serviceIntegracoes.findAll({});
            this.integracoes = data || [];
            this.aplicarIntegracoesSelecionadas();
        },
        async uploadLogo(event) {
            const formData = new FormData();
            formData.append('file', event.files[0]);
            formData.append('origem', 'companies');
            formData.append('origemId', this.form.id);
            formData.append('tipo', 'LOGO');
            try {
                const anexo = await this.$serviceBase.post('/anexo', formData);
                this.form.urlLogo = anexo.data.url;
                await this.onBeforeSave();
                this.$toast.add({ severity: 'success', summary: 'Logo carregada com sucesso', life: 3000 });
            } catch (err) {
                this.$toast.add({ severity: 'error', summary: 'Problemas ao carregar a logo', life: 3000 });
            }
        },
        async removerLogo() {
            try {
                const { data } = await this.$serviceBase.get(`/anexo/companies/` + this.form.id);
                const anexosRemover = data.filter((p) => p.tipo == 'LOGO');
                for await (const anexo of anexosRemover) {
                    await this.$serviceBase.delete(`/anexo/${anexo.id}`);
                }
                this.form.urlLogo = null;
                await this.onBeforeSave();
                this.$toast.add({ severity: 'success', summary: 'Logo removida com sucesso', life: 3000 });
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Problemas ao remover a logo', life: 3000 });
            }
        },
        geradorAsoOptions() {
            this.enumGeradorAso = Object.keys(GeradorAsoEnum).map(function (type) {
                return { label: `${GeradorAsoEnum[type]}`, value: `${type}` };
            });
        },
        agendaUtilizadaOptions() {
            this.enumAgendaUtilizada = Object.keys(EnumAgendaUtilizadaEmpresa).map(function (type) {
                return { label: `${EnumAgendaUtilizadaEmpresa[type]}`, value: `${type}` };
            });
        },
        metodosLiberacaoHierarquiaOptions() {
            this.enumMetodoLiberacao = Object.keys(EnumMetodoLiberacaoHierarquia).map(function (type) {
                return { label: `${EnumMetodoLiberacaoHierarquia[type]}`, value: `${type}` };
            });
        },
        destinatarioViaAsoOptions() {
            this.enumDestinatarioViaAso = Object.keys(DestinatarioViaAsoEnum).map(function (type) {
                return { label: `${DestinatarioViaAsoEnum[type]}`, value: `${type}` };
            });
        },
        async loadConfigAso() {
            if (!this.$route.params.id) return;
            const { data } = await this.$serviceBase.get(`/configuracao-aso/company/${this.$route.params.id}`);
            this.configAso = data != '' ? data : {};
        },
        async salvarConfigAso() {
            if (this.form.integracaoSoc) {
                try {
                    this.configAso.idCompany = this.form.id;
                    delete this.configAso.id;
                    await this.$serviceBase.post('/configuracao-aso/createOrUpdateCompany', this.configAso);
                    return;
                } catch (err) {
                    this.$toast.add({ severity: 'error', summary: 'Erro ao salvar configurações do ASO', life: 3000 });
                    return;
                }
            }
            await this.$serviceBase.delete(`/configuracao-aso/company/${this.form.id}`);
        },
        verificaNumerosSeparadosPorVirgula(str) {
            const numeros = str.split(',');
            for (let i = 0; i < numeros.length; i++) {
                if (!/^\d+$/.test(numeros[i].trim())) {
                    return false;
                }
            }
            return true;
        }
    }
};
</script>

<style lang="scss" scoped>
.user-photo-template {
    border: 1px solid #ced4da;
    border-style: solid;
    width: 70px;
    height: 70px;
    align-self: center;
    border-radius: 100%;
    overflow: hidden;
    position: relative;
}

.user-photo {
    width: initial;
    height: inherit;
    max-width: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.m-4 {
    z-index: 0;
}
</style>
